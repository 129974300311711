import { useParams } from 'react-router-dom';

const hackList = {
  isScheduleDefaultList: ['digital1to1-winter-2023', 'tince23', 'digital1to1-italy-2023', 'digital1to1-travel-2023', 'retailluxurymbaforum', 'digital1to1-spring-2024', 'alpuraconave2024', 'convencion2024', 'abril24', 'juin2024', 'digital1to1-summer-2024', 'junio24', 'auumm2024', 'fpa2024', 'agosto24', 'digital1to1-italy-2024', 'digital1to1-winter-2024'],
  changeDefaultLiveNowIcon: ['tince23'],
  changeLiveNowDefaultTitle: ['tince23'],
  sortAttendeesByLastName: ['tince23'],
  skipAnalyticsTrackings: ['voannualnetworkmeeting2022', 'netwirkig'],
  hackedForOnClickActivityCard: ['investorsday', 'surainvestmentforum', '11forodeemisoras'],
  isPureBlackActivityCard: ['ss23', 'netwirkig', '22pro5', 'demo-juanortega'],
  loggedOutBgLayout: ['cbg'],
  hideInteractivitySection: ['netwirkig', 'pernodricardsaconference', 'journees-naturalite'],
  hideLiveNow: ['e-glm-2022', '22pro5', 'tdc-conference-nlc'],
  hideTabDailyStreaming: ['elearning-africa-primary-secondary-education', 'ettc2021', 'languages_and_the_media'],
  hideMainStage: ['50b1', 'jli-global-essay-prize-2024'],
  hideAttendeeTypesFilter: ['assisesausim2024'],
  hideWaitingMessage: ['gifas-delegation-to-india'],
  changeOrderSponsorsByAmount: ['gifas-delegation-to-india'],
};

const configs = {
  limitLocaleDropdownOptions:
    {
      '2022edition': ['en', 'tr'],
      networkingeventtia: ['en', 'es'],
      'demo-juanortega': ['en', 'es'],
    },
};

const useHack = (hackName) => {
  const { eventUri } = useParams();
  return hackList[hackName]?.includes(eventUri);
};

export const useEventUriHackConfig = (hackName) => {
  const { eventUri } = useParams();
  const hackConfig = configs[hackName];

  return hackConfig?.[eventUri];
};

export default useHack;
